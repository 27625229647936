import React from 'react';
import styled from 'styled-components';
import { Logo } from '../logo';
import { Marginer } from '../marginer';
import { Button } from '../button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faMapMarkerAlt } from '@fortawesome/fontawesome-free-solid'
import { theme } from '../../theme'
import MyMap from '../map'
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import MapImage from '../../assets/pictures/map.png';
import { Col, Row, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IconContext } from "react-icons";
import { BsTelephoneFill } from 'react-icons/bs';
import { GiPositionMarker } from "react-icons/gi";
import { GrFacebook ,GrLinkedin} from "react-icons/gr";
import { FaFax, FaFacebookSquare } from 'react-icons/fa';
import { AiFillLinkedin } from "react-icons/ai";
import { MdEmail } from 'react-icons/md';
import './footer.scss';
const FooterContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
padding: 10px;

position: relative;
min-width: 270px;


`
    ;
const FooterContainerwithimg = styled.div`
width: 100%;
display: flex;
flex-direction: column;
padding: 10px;
background:
position: relative;
min-height:300px;
`
    ;/* @media screen and (max-width: 780px) {
  min-height:250px;
}background:#554555;*/

const Nav = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
background-color: rgb(1,9,35);
position: relative;min-height: 300px;
padding : 10px 100px
`;/*

*/
/* @mediascreenand (max-width: 780px) {
    padding : 10px 0px;
}*/
const ContaintContainer = styled.div`
margin-top: 1em;
display: flex;
padding-left : 10px ;
padding-right : 10px ;
min-height: 100%;
 

`;
const ContaintContainersocial = styled.div`

margin-top: 1em;
display: flex;
padding : 10px ;
padding-left : 23px ;
padding-right : 23px ;


@media screen and (max-width: 780px) {
    height: 100px;
}

`;/*align-items: center !important;
justify-content:  center !  important;*/
const ContaintContainerwithimg = styled.div`
min-width: 300px;
min-height: 400px;

margin-top: 1em;
display: flex;
padding : 10px ;
 


`;
/*
 @media screen and (max-width: 780px) {
    padding : 10px 0px;
}background-color: rgb(50,0,0);
align-items: center !important;
justify-content:  center !  important; */



const Title = styled.h1`

font-size : 18px;
font-weight:500;
font-height:1em;
color: #fff;
margin:0;
padding-top:10px;
`;
const Text = styled.h1`
font-size : 12px;
font-weight:500;
font-height:1em;
margin:0;
color:#fff;
transition: all 200ms ease-in-out;
cursor: pointer;
text-align: left;
user-select:text;
padding: 6px 0;

`;
/*
margin: 10%;
position: absolute;
top:20%
left: 40%;*/
const Containnericon = styled.div`
position: absolute;
top:20%

transform: translate(-50%, -50%)

`;
/*display: flex; */
const Containnerimg = styled.img`


justify-content: center;

width: 100%;
flex-wrap: wrap;
max-width: 400px; /* whatever you desire */
/* center the container */

align-items: center !important;
justify-content:  center !  important;
`;
const MapImg = styled.img`
    height: 170px;
    width:96%;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
    margin: 10px ;

    @media screen and (max-width: 780px) {
        margin-left: 0;
        width: (max-width<400) ?  100% : 80%;
    }
`;
const MarkerIcon = styled.div`
    color: #fff;
    font-size: 8px;
    transition: all 200ms ease-in-out;
    cursor: pointer;
`;
const Link = styled.a`
    text-decoration: none !important;

    &:hover {
        .rowIcon{
            color:${theme.primary};
        }
        .rowText{
            color:${theme.primary};
        }

    }
`;


const RowAdd = styled.div`
    display: flex;
    flex-direction: row;
    padding : 0px 5px;

`;
const clickable={}
export function Footer(props) {
    const showInMapClicked = () => {
        window.open("https://maps.google.com?q=" + 36.203821 + "," + 5.451043, 15);
    };
    const [t, i18n] = useTranslation();
    const handleApiLoaded = (map, maps) => {
        // use map and maps objects
    };

    const location = {
        address: '1600 Amphitheatre Parkway, Mountain View, california.',
        lat: 37.42216,
        lng: -122.08427,
    }


    return <Nav style={{padding:0}}>

        <Container  className="ba"  style={{padding:0}}  >
            <Row>
                <Col >
                    <FooterContainer>
                        <Container>
                            <Col style={{ padding:0 }}>
                                <Title className={i18n.language}>{t("footer.contactUs")} </Title>
                            </Col>
                        </Container>
                        <ContaintContainer>
                            <Container fluid>
                                <Row style={{ padding: 0  }}  >

                                    <Col  className="icons" xs={1}>
                                        <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                                            <div>
                                                <BsTelephoneFill />
                                            </div>
                                        </IconContext.Provider></Col  >
                                    <Col  xs >  <Text className={i18n.language} >   044756515 </Text> </Col  >
                                </Row>

                                <Row  >

                                    <Col  className="icons" xs={1}>
                                        <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                                            <div>
                                                < FaFax />
                                            </div>
                                        </IconContext.Provider></Col  >
                                    <Col xs >  <Text className={i18n.language}>   044756273  </Text> </Col  >
                                </Row>
                                <Row  >


                                    <Col  className="icons" xs={1}>
                                        <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                                            <div>
                                                <MdEmail />
                                            </div>
                                        </IconContext.Provider></Col  >
                                    <Col  xs >  <Text >contact@adhprotecleans.com</Text> </Col  >
                                </Row>
                            </Container>
                        </ContaintContainer>
                    </FooterContainer>
                </Col>
                <Col ><FooterContainer>
                    <Container  >
                        <Col  >
                            <Title   className={i18n.language}>{t("footer.socialmedia")}</Title>
                        </Col>
                    </Container>

                    <ContaintContainersocial className="icon_social_media">
                        <IconContext.Provider  value={{ color: "white", className: "global-class-name" }}>

                            <GrFacebook  style={{ marginLeft: '.5rem'  }}
                         
                            onClick={()=> window.open("https://www.facebook.com/ADH-protecleans-106227421896818/", "_blank")} size={40} />
                            <GrLinkedin style={{ marginLeft: '.5rem' , marginRight: '.5rem' }}  onClick={()=> window.open("https://www.linkedin.com/company/adh-protecleans-algeria/", "_blank")} size={40} />


                        </IconContext.Provider>

                    </ContaintContainersocial>



                </FooterContainer></Col>
                <Col >
                    <FooterContainerwithimg>
                        <Container >
                            <Col style={{ padding:0 }}>

                                <Title  className={i18n.language}>{t("footer.adrs")} </Title>
                            </Col>
                        </Container>
                        <ContaintContainerwithimg style={{ padding:0 }}>
                            <Container fluid   >

                                <Col style={{ padding:0 }}>
                                    <Link onClick={showInMapClicked} target="/blank" className={i18n.language}>
                                        <RowAdd >
                                            <IconContext.Provider  value={{ color: "white", className: "global-class-name" }}>
                                                <div>
                                                    <GiPositionMarker />
                                                </div>
                                            </IconContext.Provider>

                                            <Marginer direction='horizontal' margin="5px" />
                                            <Text className="rowText">{t("footer.address")}</Text>

                                        </RowAdd>
                                    </Link>
                                    <MapImg onClick={showInMapClicked} src={MapImage} />
                                    <Marginer direction='vertical' margin="4em" />
                                </Col>


                            </Container>    </ContaintContainerwithimg>
                    </FooterContainerwithimg>
                </Col>
            </Row>

        </Container>

    </Nav>

}