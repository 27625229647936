import React, {useState} from 'react';
import styled from "styled-components";
import {Marginer} from '../components/marginer';
import {Button} from '../components/button';
import { DownArrow } from '../components/downArrow';
import {Element, scroller} from 'react-scroll';
import {theme} from '../theme.jsx';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import PlantBack from '../assets/Background/old.jpg'
import PlantBackAr from '../assets/Background/oldArabic.jpg'
import PlantBackPhone from '../assets/Background/oldPhone.jpg'

import clinomine from '../assets/ProductPhotography/Clinofine-1kg.jpg'
import ButtonAppBar from '../components/navbarAgriculture';
import Paper from '@material-ui/core/Paper';
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";

const FinalTopContainer = styled.div`
    background-color: ${theme.greenDark};
    height:100vh;
    max-height:100vh;
    position:relative;
`;

const NintyContainer = styled.div`
    width : 100%;
    height : 100vh;
    padding : 0;
    position: relative;
`;

const TopContainer = styled.div`
    width : 100%;
    height : 100vh;
    padding : 0;
    position: relative;
    background-position: center;
    background-size: cover;
    transform : [ { scaleX : 2 } ];
    borderBottomStartRadius : 200;
    borderBottomEndRadius : 200;
    overflow : 'hidden';
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
    
`;

const HolderDiv = styled.div`
    background-color: black;
    width : 5%;
    height : 100vh;
`;

const BackgroundFilter = styled.div`
    position: 'absolute';
    width: 100%;
    height:100%;
    padding: 0px 15px;
    background-color: rgba(0, 0, 0, 0.05);
    display : flex;
    flex-direction: column;
    align-items: start;
    flex : 1;
    justify-content : center;
    padding-inline-start: 7%;
    @media screen and (max-width: 1060px) {
        align-items: center;
        padding-inline-start: 5%;
        padding-inline-end: 5%;


      }


`;

const MotivationalText = styled.h1`
margin : 0;
font-size : 50px;
font-weight:bolder;
font-height:1.4;
color: ${theme.greenHerb};
text-align: center;
font-weight: bold;
margin: 0;
text-shadow: 5px 4px 11px rgb(0,0,0), 0 2px 5px rgb(0,0,0);

`;

const ContainerPadded = styled.div`
    margin-top:-64px;
`;

const PaperCustom= styled.div`
    background-color: green;
`;



export function TopSection (props) {

    const [t, i18n] = useTranslation();
    const scrollToNextSection = () => {
        scroller.scrollTo("servicesSection",{smooth : true, duration:1500})
    }

    const [value, setValue] = React.useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <FinalTopContainer>
        <ButtonAppBar/>
        <NintyContainer>
            <TopContainer style={{
                backgroundImage: i18n.dir() == 'rtl' ? `url(${PlantBackAr})` : `url(${PlantBack})`
                }}>
                <BackgroundFilter  className = {i18n.language} padding-inline-start= "15%">
                    <Marginer direction = 'vertical' margin='10em'/>
                    {/*<BigText className = {i18n.language} fontWeight = 'bolder !important'>{t('navbar.ADH')}</BigText>*/}
                    <ContainerPadded >
                        <MotivationalText className = {i18n.language+'agr'} >{t('agriculture.title')}</MotivationalText>
                        <MotivationalText className = {i18n.language+'agr'}>{t('agriculture.and')}</MotivationalText>
                        <MotivationalText className = {i18n.language+'agr'}>{t('agriculture.plant')}</MotivationalText>
                    </ContainerPadded>
                </BackgroundFilter>
            </TopContainer>
        </NintyContainer>
        {/*
        <PaperCustom>
                <Tabs
                    value={value}
                    indicatorColor='primary'
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                    className={i18n.language}
                    TabIndicatorProps={{
                        visibility: false
                    }}
                    >
                    <Tab label = {t("productSection.agriculture")} color="white"/>
                    <Tab style={{paddingLeft: 0, paddingRight: 0}} label = {t("productSection.aviculture")} color="inherit"/>
                </Tabs>
            </PaperCustom>
        */}

    
        </FinalTopContainer>

}