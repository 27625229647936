import React, {useEffect, useState , Suspense ,Loader, BoxLoading, Text} from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { Marginer } from '../../components/marginer';
import Spinner from 'react-bootstrap/Spinner'
import { SectionTitle } from '../../components/sectionTitle';
import BackgroundImg from "../../assets/ProductPhotography/Clinofine_1kg_Transparent.png";
import FiveKg from "../../assets/ProductPhotography/Clinofine_5kg_Transparent.png";
import { useLocation } from "react-router-dom";
import LogoFami from '../../assets/logo/famiQs.png';
import LogoEco from '../../assets/logo/ecocert.png';
import Logoprganik from '../../assets/logo/organik.png';
import 'bootstrap/dist/css/bootstrap.min.css';   
import 'bootstrap/dist/css/bootstrap-grid.css';   
import "slick-carousel/slick/slick.css";
import 'slick-carousel/slick/slick-theme.css'
import axios from 'axios';
import Aos from 'aos';
import 'aos/dist/aos.css';
import {theme} from '../../theme'
import {useTranslation} from 'react-i18next';
import { FiChevronRight } from 'react-icons/fi';
import { FiChevronLeft } from 'react-icons/fi';
import { AutoRotatingCarousel} from "material-auto-rotating-carousel";
import pageOne from '../../assets/products/clinofine/ClinofineAll.png'
import Carousel from 'react-bootstrap/Carousel' 
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Hamburger from 'hamburger-react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import i18n from 'i18next';
import { useHistory } from "react-router-dom";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Box } from '@material-ui/core';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  appBar: {
    backgroundColor: '#F4F1F4',
    boxShadow: 'none',
    direction: i18n.dir(),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    backgroundColor: '#F4F1F4',
    boxShadow: 'none',
    direction: i18n.dir(),
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: i18n.dir() == 'ltr' ? drawerWidth : 0,
    marginRight: i18n.dir() == 'rtl' ? drawerWidth : 0,

    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color:'black'
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor:'#F4F1F4'

  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: i18n.dir() == 'ltr' ? -drawerWidth : 0,
    marginRight: i18n.dir() == 'rtl' ? -drawerWidth : 0,

  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen, isMobile }) => {
    return (
      <div>
        {/* <Button onClick={() => setHandleOpen({ open: true })}>Open carousel</Button> */}
        <AutoRotatingCarousel
          open={handleOpen.open}
          onClose={() => setHandleOpen({ open: false })}
          onStart={() => setHandleOpen({ open: false })}
          autoplay={false}
          //mobile={isMobile}
          style={{ position: "absolute", overflow: "hidden" }}
        >
            <ExplainPic>
            <ExplainPicPic src={pageOne} />
            </ExplainPic>
           
  
        </AutoRotatingCarousel>
      </div>
    );
  };

const OutsiderContainer = styled.div`
    height:100vh;
    width: 100%;
    display : flex;
    margin-top: 4em;
    flex-direction: row;
    align-items: center;
    
    flex-grow:1;
    justify-content: center;
    position:relative;
    background-color: ${theme.light};
    padding : 2em 3em;
    @media screen and (max-width: 780px) {
        flex-direction: column;
        padding : 0;
        height:100%;
    }

`;

const BoxDetails = styled.div`
    height:30%;
    width: 100%;
    display : flex;
    margin-top: 1em;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    justify-content: center;
    position:relative;
    background-color:${theme.light};
    padding : 20px 0px;
    @media screen and (max-width: 780px) {
      flex-direction: column;
      padding-top:20%;
      height:100%;
      margin-top:4em;
    }

`;

const soldSession = styled.div`
    height:10%;
    width: 100%;
    display : flex;
    margin-top: 1em;
    flex-direction: row;
    align-items: center;
    flex-grow:2;
    justify-content: center;
    position:relative;
    padding : 2px 0px;
    @media screen and (max-width: 780px) {
      flex-direction: row;
      padding :10px;
      height:100%;
      margin-top:4em;
    }
`;

const DescriptionDetails = styled.div`
    height:30%;
    width: 100%;
    display : flex;
    margin-top: 0em;                                                                                                                                                                                                                                                                                                                                                                                                             
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    justify-content: center;
    position:relative;
    background-color:${theme.light};
    padding :0em 0em;
    @media screen and (max-width: 780px) {
        flex-direction: column;
        padding :0px;
        height:100%;
    }

`;
const Details = styled.div`
    height:7%;
    width: 100%;
    display : flex;
    margin-top: 4em;
    flex-direction: row;
    align-items: center;
    flex-grow:1;
    justify-content: center;
    position:relative;
    background-color:red;
    padding : 1em 0em;
    @media screen and (max-width: 780px) {
        flex-direction: column;
        padding : 0;
        height:100%;
    }

`;

const ContainerTop = styled.div`
    display : flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    justify-content: flex-start;
    max-height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: 40%;
    padding: 4em 0em;
    @media screen and (max-width: 780px) {
        width: 100%;
        max-height: 100%;
       
        padding:27em 3em;

    }
`;

const UpperDiv = styled.div`
    height: 100vh;
    width: 35%;
    align-items: center;
    justify-content: center;
    display : flex;
    flex-direction: column;
    padding : 2em 0em;
    @media screen and (max-width: 780px) {
      height: 70vh;
      padding-top: 200px;
    };
`;

const Container = styled.div`
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display : flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    padding : 0em 2em;
    justify-content: center;
    overflow: 'scroll',
    flex-shrink : 1;
    @media screen and (max-width: 780px) {
        width: 100%;
        padding : 0;
        overflow: auto,
    }
`;

const SectionTitleCentered = styled(SectionTitle)`
    font-size :  6vw;
    padding-top:10%;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
    @media screen and (max-width: 780px) {
        padding-top:100%;
        font-size :  7vw;
       

    }
`;

const SectionTitleCus = styled(SectionTitle)`
    text-align : center;
    color:#fff;
    background-color: ${theme.dark};
    width : calc(100% + 64px) !important;
    padding: 10px 10px;
    left:0;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
    @media screen and (max-width: 780px) {
        width : 100% !important;
        padding:0px 0px;

    };
`;
const SectionTitleCusDescription = styled(SectionTitle)`
        text-align : center;
        color:#fff;
        background-color: ${theme.dark};
        width : calc(100% + 64px) !important;
        padding: 10px 10px;
        left:0;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
        @media screen and (max-width: 780px) {
            width : 100% !important;
            padding:0px 0px;

        };
`;

const ProductPic = styled.img`
    width:  35vw;
    height: 325px;
    -o-object-fit: contain;
    object-fit: contain;
    @media screen and (max-width: 780px) {
        width: 50vw;
        height: 225px;
        
    }

`;

const CerRow = styled.div`
    width: 100%;
    display : flex;
    flex-direction: row;
    align-items: center;
    flex-grow:1;
    padding : 2em 2em;
    justify-content: center;
    flex-shrink : 1;

`;

const Loading = styled.div`
    width: 100%;
    height:100%;
    display : flex;
    flex-direction: Column;
    align-items: center;
    flex-grow:1;
    padding : 25em 2em;
    justify-content: center;
    flex-shrink : 1;

`;

const LogoImgFami = styled.img`
    height : 40px;  
    @media screen and (max-width: 480px) {
        height : 20px;    
    }
`;

const LogoImgEco = styled.img`
    height : 50px;
    @media screen and (max-width: 480px) {
        height : 25px;   
    }
`;

const LogoImgTurk = styled.img`
    height : 60px;
    @media screen and (max-width: 480px) {
        height : 30px;    
    }
`;

const ContainerUl = styled.div`
    padding:0px 7em;
    align-items:center;
    text-align: -webkit-auto;
`
;
const ContainerUlDescription = styled.div`
    padding:0px 2em;
    font-weight: bold;
    align-items: center;
    text-align: center;
`;
const ContainerButton = styled.div`
    display:flex;
    flex-direction: row;
    transition: all 200ms ease-in-out;
    align-items:flex-end;
    &:hover{
        .arrow{
            color:${theme.primary};   
        }
        h1{
            color:${theme.primary};   
        }

    }
`;

const TextButton = styled.h1`
    font-size : 22px;
    font-weight:700;
    font-height:1.4;
    color: #fff;
    text-align: center;
    cursor : pointer;
    margin:0;
    transition: all 200ms ease-in-out;
    @media screen and (max-width: 780px) {
        text-align: center;
    }
`;

const Icon = styled(FiChevronRight)`
    color: #fff;
    font-size: 22px;
    cursor:pointer;

`;

const IconAr = styled(FiChevronLeft)`
    color: ${theme.primary};
    font-size: 22px;
    cursor:pointer;

`;

const styleI = {
    width:'100%',
    height:'70vh',  
}

const ExplainPic = styled.div`
    width:100%;
    display:flex;
    align-item:center;
    justify-content:center;
    overflow-y: scroll;
    pointer-events:none;

`;

const ExplainPicPic = styled.img`
    width: 100%;
    pointer-events:none;

`;

const Texts = styled.h6`
    text-align: center;
    margin:0px;
`;

const ContainerButtonCus = styled(ContainerButton)`
    text-align : center;
        color:#fff;
        background-color: ${theme.dark};
        width : calc(100% + 64px) !important;
        padding: 20px 10px;
        left:0;
        align-items:center;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
        @media screen and (max-width: 780px) {
            width : 100% !important;

        };
`;

const CarouselContainer = styled.div`
    position: relative;
   
    @media screen and (max-width: 780px) {
     

  };
`;

const Mycarousel = styled.div`
`;

const carouselStyle = {
    interval: 500,
    cycle: true,
};

const DivForImage = styled.div`
    width : 100%;
    height: 45%;
    padding :10px;
   
    align-items:center;
    justify-content: center;
    @media screen and (max-width: 780px) {
      width : 100% !important;
      height:10%;
      padding :5px 30px;

  };
    

`;

const CursulitemContainer = styled.div`
    width : 100%;
    height: 50%;
    padding :0px;
    align-items:center;
    justify-content: center;
    @media screen and (max-width: 780px) {
      width : 100% !important;
      padding:0px 0px 0px 0px;
      
   

  };
    

`;
const server="https://admin.adhprotecleans.com";
export function ProductPresClino (props) {
    const [t, i18n] = useTranslation();
    const [handleOpen, setHandleOpen] = useState({ open: false });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [Products, setProducts] = React.useState(null);
    const [Boxes, setBox] = React.useState(null);
    const [Descriptions, setDescription] = React.useState(null);

    const handleClick = () => {
      setHandleOpen({ open: true });
    };

    const handleClickTwo = event => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    }; 

    useEffect(() => {
        Aos.init({duration:400});
    },[]);
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    }; 
    const [idavg,setavr]=useState();
    const [idarg,setagr]=useState();
    const [categoriees,getCategories]=useState();
    const mygetcategories = async () => {
        console.log("nav bar")
        const cats = await axios.get(server+'/api/v1/category',{
            headers: {
              "Access-Control-Allow-Origin": "*"
            }
          })
        console.log(cats.data.category[1]);
        setagr(cats.data.category[1].id);
        setavr(cats.data.category[0].id);
        getCategories(cats.data.category);
    
       };
       
       useEffect(() => {
        mygetcategories();
       }, []);
    const [show, setShow] = useState(false);
    const history = useHistory();
    const navigateToHome = () => history.go(-2); 
    //const navigateToAgr = () => history.goBack();
    //const navigateToAvc = () => history.replace('/zootechnie');
    const navigateToAgr = () => history.push({pathname:'/agriculture', state:{idscat: idarg}}); //eg.history.push('/login');
    const navigateToAvc = () => history.push({pathname:'/zootechnie', state:{idscat: idavg}});

    const location = useLocation();

     
    const mydescriptiondetails = async () => {
      
      const mdcd = await axios.get('https://admin.adhprotecleans.com/api/v1/product/'+location.state.ids,
      {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      });
      setProducts(mdcd.data.product); 
      console.log("frf"+mdcd.data.product)
      setBox(mdcd.data.product.boxes);
      
      setDescription(mdcd.data.product.descriptions)
    }
  useEffect(() => {
      mydescriptiondetails();
   
     }, []);  

  if (!Products || !Boxes  ) 
  return (
     <Loading>  
      <Spinner animation="border" role="status" variant="success">
      </Spinner>
      <Marginer direction = 'vertical' margin = '1em'/>
      <h5>Loading...</h5>
      </Loading>);

  if(!Descriptions)    return <div></div>;

const mybox=Boxes.map((e)=>{

 return <Carousel.Item>
  < CursulitemContainer>  
      
         <DivForImage>
            <ProductPic src={e.path_org}  />
         </DivForImage> 
         <BoxDetails> 
         <Container data-aos="fade-up" >
         <SectionTitleCusDescription  text-align = 'center  !important' className = {i18n.language}>Description </SectionTitleCusDescription>
         
         <ContainerUlDescription>
         <Marginer direction = 'vertical' margin = '1em'/>
      
          <h5> {Products.name}  {e.weight}</h5>
           
          <soldSession>
            {e.lastpromotion== null ? " " : <h5 style={{color:"green" , fontWeight:"bold"}}> {e.lastpromotion.new_price } </h5> }
            <Marginer direction = 'vertical' margin = '0.2em'/>
            {e.lastpromotion== null ? " " : <h6 style={{color:"grey", textDecorationLine: 'line-through',}}> { e.lastpromotion.old_price + " DA " } </h6> }
            {e.lastpromotion== null ? " " : <h7 style={{color:"black"}}> {  "De "+ e.lastpromotion.start_date + " à "+ e.lastpromotion.end_date}</h7> }
         
          </soldSession>
           <Marginer direction = 'vertical' margin = '2em'/>
          </ContainerUlDescription>
          </Container>
        
         </BoxDetails> 
        </CursulitemContainer>
     </Carousel.Item>
  
});

const Description_Info = Descriptions.map((description)=>{
          return <DescriptionDetails>
                  <SectionTitleCus text-align = 'center  !important' className = {i18n.language}>{description.title}</SectionTitleCus>
                  <ContainerUl>
                  <Marginer direction = 'vertical' margin = '2em'/>
                  <div  dangerouslySetInnerHTML={{__html: description.content}}></div>
                 </ContainerUl>
                 <Marginer direction = 'vertical' margin = '2em'/>
                 </DescriptionDetails>       
                        
               })


        return <OutsiderContainer className={classes.root} >
          <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })} >
          <Toolbar>
            <IconButton
              style={{padding: 0}}  
              aria-controls="simple-menu" 
              aria-haspopup="true" 
              onClick={handleClickTwo} 
              color="inherit"
              className={clsx(classes.menuButton, open && classes.hide)}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={navigateToHome}className = {i18n.language} fontFamily="font-family: 'Cairo', sans-serif">{t('navbar.home')} </MenuItem>
              <MenuItem onClick={navigateToAgr} className = {i18n.language}>{t("productSection.agriculture")}</MenuItem>
              <MenuItem className = {i18n.language} onClick={navigateToAvc}>{t("productSection.aviculture")}</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
     {/*
      ${theme.dark}
     <Drawer
       className={classes.drawer}
       variant="persistent"
       anchor= {i18n.dir() === 'ltr' ? "left" : "right"}
       open={open}
       classes={{
         paper: classes.drawerPaper,
       }}
     >
       <div className={classes.drawerHeader}>
         <IconButton onClick={handleDrawerClose}>
           {i18n.dir() === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
         </IconButton>
       </div>
       <List className = {i18n.language+"drawer"}> 
       <ListItem button key={t('navbar.home')} onClick={navigateToHome}>
           <ListItemText className = {i18n.language} primary={t('navbar.home')}/>
               </ListItem>
           <ListItem onClick={navigateToAgr} className = {i18n.language} button key={t("productSection.agriculture")}>
             <ListItemText  className = {i18n.language} primary={t("productSection.agriculture")}/>
           </ListItem>
           <ListItem onClick={navigateToAvc} className = {i18n.language} button key={t("productSection.aviculture")}>
             <ListItemText primary={t("productSection.aviculture")}/>
           </ListItem>
       </List>
       </Drawer>
      */}

       <UpperDiv data-aos="fade-right">
           <SectionTitleCentered className = {i18n.language}> {Products.name} </SectionTitleCentered>
           <Marginer direction = 'vertical' margin = '2em'/>
           <CarouselContainer>
           <Mycarousel className='mycarousel'  style={carouselStyle} >  
           <Carousel fade={true}> 
           {mybox}
           </Carousel> 
            {  /*<Carousel fade={true}> 
                   <Carousel.Item>  
                   <DivForImage>
                           <ProductPic
                               src={BackgroundImg}
                           />
                       </DivForImage>
                   </Carousel.Item>
                   <Carousel.Item> 
                       <DivForImage>
                           <ProductPic
                               src={FiveKg}
                           />
                       </DivForImage>
                   </Carousel.Item>
              
                </Carousel>  
                    */}
           </Mycarousel> 
         </CarouselContainer> 

       </UpperDiv> 
   <ContainerTop>
       
       <Container data-aos="fade-up" >

       {Description_Info}
           {   /*    <SectionTitleCus text-align = 'center  !important' className = {i18n.language}>{t("products.Clinofine.avantages.title")}</SectionTitleCus>

           <ContainerUl>
           <Marginer direction = 'vertical' margin = '2em'/>
               <ul className = {i18n.language}>
                   <li className = {i18n.language}>{t("products.Clinofine.avantages.1")}</li>
                   <li className = {i18n.language}>{t("products.Clinofine.avantages.2")}</li>
                   <li className = {i18n.language}>{t("products.Clinofine.avantages.3")}</li>
                   <li className = {i18n.language}>{t("products.Clinofine.avantages.4")}</li>
                   <li className = {i18n.language}>{t("products.Clinofine.avantages.5")}</li>
                   <li className = {i18n.language}>{t("products.Clinofine.avantages.6")}</li>
                   <li className = {i18n.language}>{t("products.Clinofine.avantages.7")}</li>
                   <li className = {i18n.language}>{t("products.Clinofine.avantages.8")}</li>
                   <li className = {i18n.language}>{t("products.Clinofine.avantages.9")}</li>
                   <li className = {i18n.language}>{t("products.Clinofine.avantages.10")}</li>
               </ul>
                  </ContainerUl>*/}
           

     {    /*  <SectionTitleCus className = {i18n.language}>{t("products.Clinofine.remarques.title")}</SectionTitleCus>
           <Marginer direction = 'vertical' margin = '1em'/>
                      
           <ContainerUl>
           <Texts text-align='center !important' className = {i18n.language}>{t("products.Clinofine.remarques.subtitle")}</Texts>
           <Marginer direction = 'vertical' margin = '2em'/> 
               <ul className = {i18n.language}     display= "inline-block">
                   <li className = {i18n.language}>{t("products.Clinofine.remarques.1")}</li>
                   <li className = {i18n.language}>{t("products.Clinofine.remarques.2")}</li>
                   <li className = {i18n.language}>{t("products.Clinofine.remarques.3")}</li>
                   <li className = {i18n.language}>{t("products.Clinofine.remarques.4")}</li>      
               </ul>
                </ContainerUl>*/}
                
                  <SectionTitleCus className = {i18n.language} textAlign = 'center !important'>{t("products.Clinofine.certificate")}</SectionTitleCus>
                  <CerRow>
                      <LogoImgFami src = {LogoFami} />   
                      <Marginer direction = 'horizontal' margin = '2em'/>             
                      <LogoImgEco src = {LogoEco}/>
                      <Marginer direction = 'horizontal' margin = '2em'/>
                      <LogoImgTurk src = {Logoprganik}/>

                  </CerRow>


      {/* 
       <ContainerButtonCus className = {i18n.language} onClick={handleClick}>
               <TextButton className = {i18n.language}>{t("products.techFile")}</TextButton>
               <Marginer direction="horizontal" margin="5px"/>
               {i18n.language == "fr" ? <Icon className = 'arrow'/> : <IconAr className = 'arrow'/>}
       </ContainerButtonCus>
       <AutoRotatingCarouselModal
           handleOpen={handleOpen}
           setHandleOpen={setHandleOpen}
              />*/}

       </Container>
       <Marginer direction = 'vertical' margin = '4em'/>
       
   </ContainerTop>
   </OutsiderContainer>
    
}



        
        
        
        
        
        
        
        
        
        
        
        