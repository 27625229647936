import React, { useState } from 'react';
import SliderAgr from '../components/SliderAgr';
import styled from 'styled-components';
import { SectionTitle } from '../components/sectionTitle';
import {Marginer} from '../components/marginer'
import '../css/App.scss';
import { useLocation } from "react-router-dom";
import {theme} from '../theme';
import {useTranslation} from 'react-i18next';
import {TopSection} from './topSectionAgr';
import { useHistory } from "react-router-dom";

const Container = styled.div`
    width:100%;
    overflow-x:hidden;
    height:100%;
    display: flex;
    flex-direction: column;
    background-color: ${theme.light};
`;


export function ProductAgr(props) {
    const history = useHistory();
    const location = useLocation();


    
    const [t, i18n] = useTranslation();
    history.listen((action) => {
        window.scrollTo(0, 0)
       
    })

 
        return <Container> 
                <TopSection/>
                <Marginer direction='vertical' margin='5em'/>
                <SectionTitle className = {i18n.language}>{t("productSection.ourProducts")}</SectionTitle>
                <SliderAgr idcategory={props.location.state.idscat}/>
            </Container>
}