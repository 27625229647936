import React from 'react'
import styled from "styled-components"
import { MoreAboutSection } from './moreAboutSection';
import {Marginer} from '../../components/marginer'
import { Footer } from '../../components/footer';
import {BootstrapCarousel} from "../../components/BootstrapCarousel"
import { ProductSection } from './productSection';
import { WhyUsSection } from './whyUsSection';
import { ObligationSection } from './obligations';

import ButtonAppBar from '../../components/navbar';
import {theme} from '../../theme'
import { TopSection } from './topSection';


const PageContianer = styled.div`
width:100%;
overflow-x:hidden;
height:100%;
display: flex;
flex-direction: column;
background-color: ${theme.light};
`;



export function Homepage(prop) {
    return <div>
            <ButtonAppBar/>
            <PageContianer>
            <TopSection/>
        <Marginer direction='vertical' margin='4em'/>
        <ProductSection/>
        <Marginer direction='vertical' margin='3em'/>
        <MoreAboutSection/>
        <Marginer direction='vertical' margin='4em'/>
        <WhyUsSection/>
        <Marginer direction='vertical' margin='4em'/>
        <ObligationSection/>
        <Marginer direction='vertical' margin='4em'/>
        <Footer/>
        
    </PageContianer>
        </div>;
}
