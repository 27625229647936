import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const mapStyles = {
  width: '100%',
  height: '100vh',
};


class MyMap extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <GoogleMapReact
        defaultCenter={this.props.center}
        defaultZoom={this.props.zoom}
        style={{height: '30vh', width: '30%'}}
      >
        <AnyReactComponent
          lat={59.955413}
          lng={30.337844}
          text={'Google Map'}
        />
      </GoogleMapReact>
    );
  }
}

MyMap.defaultProps = {
  center: {lat: 59.95, lng: 30.33},
  zoom: 11
};

export default MyMap;