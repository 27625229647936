import React from 'react'
import Slider from 'react-slick';
import ProductAnimalImg from '../assets/ProductPhotography/product-4.jpg'

import { useState, useEffect } from "react";
import styled from 'styled-components'
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useHistory } from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {CardSlider} from '../components/card';
import axios from 'axios';

const server="http://websiteadmin.com";
const ContainerAll = styled.div`
    width:45%;
    max-height: 80%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding : 10px 0;
    @media screen and (max-width: 1020px) {
        width: 50%;
        @media screen and (max-width: 800px) {
            width: 60%;
            @media screen and (max-width: 670px) {
                width: 60%;
                @media screen and (max-width: 580px) {
                    width: 75%;
                    @media screen and (max-width: 480px) {
                        width: 90%;
                    }
                }
            }
            
        }
        
    }

`;

const Containerone = styled.div`
    width: 45%;
    min-height: 350px;
    padding : 0px 0;
    @media screen and (max-width: 544px) {
        width: 100% !important;
        @media screen and (max-width: 300px) {
            width: 80% !important
        }

`;


function ImageSlider(props) {
    const [ProductsZoo,setProductsZoo]=useState(null);
    const [length,setlength]=useState(false);
    useEffect(() => {
        Aos.init({duration:2000})
    },[]);


    const myzooproducts = async () => {

        console.log('tyty');
        const zooproducts = await axios.get('https://admin.adhprotecleans.com/api/v1/category/'+props.idcategory+'/product', 
        {
            headers: {
              "Access-Control-Allow-Origin": "*"
            }
          });
        //setUsers(cats.data.results);
        setProductsZoo(zooproducts.data.products);
        setlength(true);
    
       };
       
       useEffect(() => {
        myzooproducts();
       }, []);



    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
      });
      const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight
    });
    useEffect(() => (window.onresize = updateSize), []);
    let settings = {
        dots: true,
        infinite:false,
        speed:500, 
        slidesToShow:   (size.x > 800 && length== true  ) ? 2: 1,
        slidesToScroll:1,
        cssEase: 'linear'
    }
    const [t, i18n] = useTranslation();

    const history = useHistory();


    if(ProductsZoo==null)return (     
        <ContainerAll className='container mt-5 carousel'>
         <Slider {...settings}>
         </Slider>
         </ContainerAll>)

    const ZootchineProductSection=ProductsZoo.map((ProductZoo)=>{
     return (
     <Containerone>
       <CardSlider id={ProductZoo.id}   name = {ProductZoo.name} linkImage = {ProductZoo.path_org} linkNavigate = '/agriculture/clinofine' />

     </Containerone>);
    })

    return (
        <ContainerAll className='container mt-5 carousel'>
        <Slider {...settings}>            
           {ZootchineProductSection} 
        </Slider>
        </ContainerAll>
    )
}

export default ImageSlider


/*
        axios({
              method: 'get',
              url: 'http://websiteadmin.com/api/v1/category/1/product',
              headers:{
               'retry-after': 3600
             },
         
            }).then((response)=>{
              
              console.log(response["data"]["products"].length);
              if(response["status"]==200)
              {
                  setProductsZoo(response["data"]["products"]);
                  setlength(true)
              }
                
           
            }).catch((err)=>{console.log("err"+err)});
*/