import React from 'react';
import styled from 'styled-components';
import {Element} from "react-scroll"
import { SectionTitle } from '../../components/sectionTitle';
import {Marginer} from '../../components/marginer'
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';


const WhyUsContainer = styled(Element)`
    display : flex;
    flex-direction: column;
    align-items: center;
    padding : 0 1em;
    justify-content: center;
    
`;

const WhyUsInnerContainer = styled.div`
    display:flex;
    max-width: 900px;
    align-items: center;
    justify-content:center;
    @media screen and (max-width: 780px) {
        max-width: 100%;
    }
`;
const WhyUsText = styled.h6`
    font-size: 17px;
    color: #2F2F2F;
    font-weight: normal;
    line-height:1.4;
    text-align:center;
    max-width : 70%;
    background-color: 'transparent';
    @media screen and (max-width: 780px) {
        max-width: 95%;
    }
`;

const WhyUsImg = styled.img`
    height: 400px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    
    @media screen and (max-width: 780px) {
        margin-left: 0;
        width: 100%;
    }

`;

const ContainerWhite = styled.div`
    background-color: #ab4;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
`;


export function ObligationSection(props) {
    const [t, i18n] = useTranslation();

    return <WhyUsContainer>
        <SectionTitle name='Obligations' className={i18n.language}>{t("obligationSection.obligations")}</SectionTitle>
        <Marginer direction='vertical' margin='2em'/>
        <WhyUsInnerContainer>
                <WhyUsText className={i18n.language}>
                    {t("obligationSection.obligationsText")}
                </WhyUsText>
        </WhyUsInnerContainer>
    </WhyUsContainer>
}