import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Marginer } from '../../components/marginer';
import { SectionTitle } from '../../components/sectionTitle';
import BackgroundImg from "../../assets/ProductPhotography/product_3_Transparent.png";
import LogoFami from '../../assets/logo/famiQs.png';
import LogoEco from '../../assets/logo/ecocert.png';
import Logoprganik from '../../assets/logo/organik.png';
import without from '../../assets/pictures/without.png';
import withC from '../../assets/pictures/with.png';
import clsx from 'clsx';
import { useLocation } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import {theme} from '../../theme'
import {useTranslation} from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { FiChevronRight } from 'react-icons/fi';
import { FiChevronLeft } from 'react-icons/fi';
import {GlassMagnifier} from "react-image-magnifiers";
import { AutoRotatingCarousel} from "material-auto-rotating-carousel";
import pageOne from '../../assets/products/clinomine/ClinomineAll.png';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Hamburger from 'hamburger-react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import i18n from 'i18next';
import { useHistory } from "react-router-dom";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#F4F1F4',
    boxShadow: 'none',
    direction: i18n.dir(),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    backgroundColor: '#F4F1F4',
    boxShadow: 'none',
    direction: i18n.dir(),
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: i18n.dir() == 'ltr' ? drawerWidth : 0,
    marginRight: i18n.dir() == 'rtl' ? drawerWidth : 0,

    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color:'black'
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor:'#F4F1F4'

  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: i18n.dir() == 'ltr' ? -drawerWidth : 0,
    marginRight: i18n.dir() == 'rtl' ? -drawerWidth : 0,

  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),

  },
}));

const OutsiderContainer = styled.div`
height:100vh;
margin-top: 4em;
    width: 100%;
    display : flex;
    flex-direction: row;
    align-items: center;
    flex-grow:1;
    justify-content: center;
    position:relative;
    background-color: ${theme.light};
    padding : 2em 3em;
    @media screen and (max-width: 780px) {
        flex-direction: column;
        padding : 0;
        height:100%;
    }

`;

const ContainerTop = styled.div`
    display : flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    justify-content: flex-start;
    max-height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: 40%;
    padding: 4em 0em;
    @media screen and (max-width: 780px) {
        width: 100%;
        max-height: 100%;
        padding: 1em 2em;

    }
`;

const UpperDiv = styled.div`
    height: 100vh;
    width: 35%;
    align-items: center;
    justify-content: center;
    display : flex;
    flex-direction: column;
    padding : 2em 0em;
    @media screen and (max-width: 780px) {
      height: 70vh;
    };
`;

const Container = styled.div`
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display : flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    padding : 0em 2em;
    justify-content: center;
    overflow: 'scroll',
    flex-shrink : 1;
    @media screen and (max-width: 780px) {
        width: 100%;
        padding : 0;
        overflow: auto,
    }
`;
const SectionTitleCentered = styled(SectionTitle)`
    font-size :  6vw;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
    @media screen and (max-width: 780px) {
        font-size : 70px;
        font-size :  12vw;

    }
`;

const SectionTitleCus = styled(SectionTitle)`
    text-align : center;
        color:#fff;
        background-color: ${theme.dark};
        width : calc(100% + 64px) !important;
        padding: 10px 10px;
        left:0;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
        @media screen and (max-width: 780px) {
            width : 100% !important;

        };
`;

const ProductPic =  styled(GlassMagnifier)`
    width:  25vw;
    @media screen and (max-width: 780px) {
        width: 45vw;
    }
    -webkit-filter: drop-shadow(3px 3px 3px #888888	);
    filter: drop-shadow(3px 3px 3px #888888	);
`;

const CerRow = styled.div`
    width: 100%;
    display : flex;
    flex-direction: row;
    align-items: center;
    flex-grow:1;
    padding : 2em 2em;
    justify-content: center;
    flex-shrink : 1;
    

`;

const WithoutRow = styled.div`
    width: 100%;
    display : flex;
    flex-direction: row;
    align-items: center;
    flex-grow:1;
    padding : 2em 2em;
    justify-content: center;
    flex-shrink : 1;
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        width: 100%;

    }

`;

const LogoImgFami = styled.img`
    height : 40px;  
    @media screen and (max-width: 480px) {
        height : 20px;    
    }
`;

const LogoImgEco = styled.img`
    height : 50px;
    @media screen and (max-width: 480px) {
        height : 25px;   
    }
`;

const LogoImgTurk = styled.img`
    height : 60px;
    @media screen and (max-width: 480px) {
        height : 30px;    
    }
`;

const ContainerUl = styled.div`
    padding:0px 2em;
    align-items: flex-start;
    text-align: -webkit-auto;
`;

const styleI = {
    width:'100%',
    height:'70vh',  
}

const ContainerButton = styled.div`
    display:flex;
    flex-direction: row;
    transition: all 200ms ease-in-out;
    align-items:flex-end;
    align-items:center;
    justify-content:center;
    &:hover{
        .arrow{
            color:${theme.primary};   
        }
        h1{
            color:${theme.primary};   
        }

    }
`;

const TextButton = styled.h1`
    font-size : 22px;
    font-weight:700;
    font-height:1.4;
    color: #fff;
    text-align: center;
    cursor : pointer;
    margin:0;
    transition: all 200ms ease-in-out;
    @media screen and (max-width: 780px) {
        text-align: center;
    }
`;

const Icon = styled(FiChevronRight)`
    color: #fff;
    font-size: 22px;
    cursor:pointer;

`;

const IconAr = styled(FiChevronLeft)`
    color: #fff;
    font-size: 22px;
    cursor:pointer;

`;

const ColumnEmpty= styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
`;

const CaptionsRed = styled.h6`
    font-weight: bold;
    color: ${theme.redPink};
`;

const CaptionsGreen = styled.h6`
    font-weight: bold;
    color: ${theme.greenHerb};
    
`;

const ExplainPic = styled.div`
    width:100%;
    display:flex;
    align-item:center;
    justify-content:center;
    overflow-y: scroll;
        pointer-events:none;

`;

const ExplainPicPic = styled.img`
    width: 100%;
    pointer-events:none;
`;

const ContainerButtonCus = styled(ContainerButton)`
    text-align : center;
        color:#fff;
        background-color: ${theme.dark};
        width : calc(100% + 64px) !important;
        padding: 20px 10px;
        left:0;
        align-items:center;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
        @media screen and (max-width: 780px) {
            width : 100% !important;

        };
    `;


const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen, isMobile }) => {
  return (
    <div>
      {/* <Button onClick={() => setHandleOpen({ open: true })}>Open carousel</Button> */}
      <AutoRotatingCarousel
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        autoplay={false}
        //mobile={isMobile}
        style={{ position: "absolute", overflow: "hidden" }}
      >
          <ExplainPic>
          <ExplainPicPic src={pageOne} />
          </ExplainPic>
         

      </AutoRotatingCarousel>
    </div>
  );
};




export function ProductPresClinoM (props) {
    const [t, i18n] = useTranslation();


    useEffect(() => {
        Aos.init({duration:400})
        
    },[]);
    const [show, setShow] = useState(false);
    const [handleOpen, setHandleOpen] = useState({ open: false });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [Products, setProducts] = React.useState(null);
    const [Description, setDescription] = React.useState(null);
    const [Box, setBox] = React.useState(null);

    const handleClick = () => {
      setHandleOpen({ open: true });
      console.log("props.state.idis");
    };

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    }; 

    const handleClickTwo = event => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    }; 

    

    const history = useHistory();
    const navigateToHome = () => history.go(-2); 
    const navigateToAgr = () => history.goBack();
    const navigateToAvc = () => history.replace('/zootechnie');


    const location = useLocation();
    useEffect(()=>{
    console.log(location.state.ids);
    console.log('http://websiteadmin.com/api/v1/product/'+location.state.ids);
    axios({
          method: 'get',
          url: 'http://websiteadmin.com/api/v1/product/'+location.state.ids,
     
        }).then((response)=>{
          
          
          if(response["status"]==201)
          {
           // console.log(response["data"]["product"]);
            setProducts(response["data"]["product"]);
            setDescription(response["data"]["descriptions"]);
            setBox(response["data"]["box"])
          }
          
       
        }).catch((err)=>{console.log(err)});
  },[Products])
    

    return <OutsiderContainer className={classes.root}>
        <CssBaseline />
        <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        >
          <Toolbar>
            <IconButton
              style={{padding: 0}}  
              aria-controls="simple-menu" 
              aria-haspopup="true" 
              onClick={handleClickTwo} 
              color="inherit"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={navigateToHome}className = {i18n.language} fontFamily="font-family: 'Cairo', sans-serif">{t('navbar.home')} </MenuItem>
              <MenuItem onClick={navigateToAgr} className = {i18n.language}>{t("productSection.agriculture")}</MenuItem>
              <MenuItem className = {i18n.language} onClick={navigateToAvc}>{t("productSection.aviculture")}</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        {/*
        <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor= {i18n.dir() === 'ltr' ? "left" : "right"}
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {i18n.dir() === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <List className = {i18n.language+"drawer"}> 
        <ListItem button key={t('navbar.home')} onClick={navigateToHome}>
            <ListItemText className = {i18n.language} primary={t('navbar.home')}/>
                </ListItem>
            <ListItem onClick={navigateToAgr} className = {i18n.language} button key={t("productSection.agriculture")}>
              <ListItemText  className = {i18n.language} primary={t("productSection.agriculture")}/>
            </ListItem>
            <ListItem onClick={navigateToAvc} className = {i18n.language} button key={t("productSection.aviculture")}>
              <ListItemText primary={t("productSection.aviculture")}/>
            </ListItem>
        </List>
        </Drawer>
        */}


    <UpperDiv data-aos="fade-right">
  
        <SectionTitleCentered className = {i18n.language}> {t("products.Clinomine.name")} </SectionTitleCentered>
        <Marginer direction = 'vertical' margin = '3em'/>
        <ProductPic 
            imageSrc={BackgroundImg}
            allowOverFlow = "true"
            magnifierBorderColor = "transparent"
            magnifierSize = "40%"
            imageCaption="{captions[photoIndex]}"/>
    </UpperDiv> 
    <ContainerTop>
    
    <Container data-aos="fade-up" >
    <SectionTitleCus text-align = 'center  !important' className = {i18n.language}>{t("products.Clinomine.avantages.title")}</SectionTitleCus>

        <ContainerUl>
        <Marginer direction = 'vertical' margin = '2em'/>
            <ul className = {i18n.language}>
                <li className = {i18n.language}>{t("products.Clinomine.avantages.1")}</li>
                <li className = {i18n.language}>{t("products.Clinomine.avantages.2")}</li>
                <li className = {i18n.language}>{t("products.Clinomine.avantages.3")}</li>
                <li className = {i18n.language}>{t("products.Clinomine.avantages.4")}</li>
                <li className = {i18n.language}>{t("products.Clinomine.avantages.5")}</li>
                <li className = {i18n.language}>{t("products.Clinomine.avantages.6")}</li>
                <li className = {i18n.language}>{t("products.Clinomine.avantages.7")}</li>
            </ul>
            </ContainerUl>
        <Marginer direction = 'vertical' margin = '2em'/>

        <SectionTitleCus className = {i18n.language}>{t("products.Clinomine.description.title")}</SectionTitleCus>
                    
        <ContainerUl>
        <Marginer direction = 'vertical' margin = '2em'/> 
            <ul className = {i18n.language}     display= "inline-block">
                <li className = {i18n.language}>{t("products.Clinomine.description.1")}</li>
                <li className = {i18n.language}>{t("products.Clinomine.description.2")}</li>
                <li className = {i18n.language}>{t("products.Clinomine.description.3")}</li>
                <li className = {i18n.language}>{t("products.Clinomine.description.4")}</li> 
                <li className = {i18n.language}>{t("products.Clinomine.description.5")}</li>      

            </ul>
        </ContainerUl>

    <Marginer direction = 'vertical' margin = '1em'/>
    

    <WithoutRow>
        <ColumnEmpty>
            <img width='100%' src = {without} />
            <Marginer direction = 'vertical' margin = '1em'/>             
            <CaptionsRed className = {i18n.language} >{t("products.Clinomine.without")}<span className = {i18n.language} fontWeight="bold !important">{t("products.Clinomine.nameC")}</span></CaptionsRed>
        </ColumnEmpty>
        <Marginer direction = 'horizontal' margin = '5em'/>             
        <Marginer direction = 'vertical' margin = '2em'/>             

        <ColumnEmpty>
            <img width='100%' src = {withC} />
            <Marginer direction = 'vertical' margin = '1em'/>             
            <CaptionsGreen className = {i18n.language} >{t("products.Clinomine.with")}<span className = {i18n.language} fontWeight="bold !important">{t("products.Clinomine.nameC")}</span></CaptionsGreen>
        </ColumnEmpty>   
    </WithoutRow>

        <Marginer direction = 'vertical' margin = '1em'/>
        <SectionTitleCus className = {i18n.language} textAlign = 'center !important'>{t("products.Clinofine.certificate")}</SectionTitleCus>
    <CerRow>
        <LogoImgFami src = {LogoFami} />   
        <Marginer direction = 'horizontal' margin = '2em'/>             
        <LogoImgEco src = {LogoEco}/>
        <Marginer direction = 'horizontal' margin = '2em'/>
        <LogoImgTurk src = {Logoprganik}/>

    </CerRow>
    <Marginer direction = 'vertical' margin = '1em'/>

    <ContainerButtonCus className = {i18n.language} onClick={handleClick}>
            <TextButton className = {i18n.language}>{t("products.techFile")}</TextButton>
            <Marginer direction="horizontal" margin="5px"/>
            {i18n.language == "fr" ? <Icon className = 'arrow'/> : <IconAr className = 'arrow'/>}
    </ContainerButtonCus>
    <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
    />

    </Container>
    <Marginer direction = 'vertical' margin = '4em'/>
    
    </ContainerTop>
    
    </OutsiderContainer>
  
    
  }
    
