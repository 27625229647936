import React from 'react';
import styled from 'styled-components'
import {useTranslation} from 'react-i18next';
import { Card } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import ProductPlantImg from '../../assets/ProductPhotography/Clinofine-1kg.jpg'

const CardOutsider = styled.div`
    overflow: hidden;
    margin:10px 10px;
    width: 85%;
    height: 350px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    margin:20px 20px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-image: url(${ProductPlantImg})
    margin-right:20px;
`;

const InnerDiv = styled.div`
    height: 100%;
    width: 100%;
    position : relative;
`;

const Image = styled.img`
    object-fit:cover;

    width: 100%;
    height: 100%;
`;

const UpperDiv = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right:0;
    height:25%;
    width:100%;
    background-color: rgba(0,0,0, 0.3);
    color: white;
    align-items: center;
    display:flex;
    justify-content:center;
`;

const Text = styled.h3`
    text-align:center;
    font-weight: bold;


`;


export function CardSlider(props) {

    const history = useHistory();
    const [t, i18n] = useTranslation();
    const {id , name, linkImage, linkNavigate } =  props;
    const navigateTo = () => history.push({
        pathname: linkNavigate,
        state: { ids: id }
      });//eg.history.push('/login');

    return <CardOutsider onClick={navigateTo}>
        <InnerDiv>
            <Image src = {linkImage}/>
            <UpperDiv>
            <Text className = {i18n.language}>{name}</Text>
            </UpperDiv>
        </InnerDiv>
        
    </CardOutsider>;
}