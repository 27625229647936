import React, {useEffect,useState} from 'react';
import styled from 'styled-components';
import {Element} from "react-scroll"
import { SectionTitle } from '../../components/sectionTitle';
import AboutImgUrl from '../../assets/pictures/company-building.jpeg';
import {Marginer} from '../../components/marginer';
import i18next from '../../i18next'
import { useTranslation } from 'react-i18next';
import Aos from 'aos';
import 'aos/dist/aos.css';
import ReactPlayer from "react-player";
import Slider from 'react-slick';
import FiveKg from "../../assets/ProductPhotography/Clinofine_5kg_Transparent.png";
import BackgroundImg from "../../assets/ProductPhotography/Clinofine_1kg_Transparent.png";
import { colors } from '@material-ui/core';
const MoreAboutContainer = styled(Element)`
    display : flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    padding : 0 1em;
    justify-content: center;
    background-color: transparent;
    
`;

const AboutContainer = styled.div`
    display:flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width: 780px) {
        flex-direction: column;
        max-width: 100%;
        justify-content: center;
    }
`;

const AboutText = styled.h6`
    font-size: 17px;
    color: #2F2F2F;
    max-width: 30%;
    font-weight: normal;
    line-height:1.4;
    background-color: 'transparent';
    text-align :  -webkit-auto;
    @media screen and (max-width: 780px) {
        text-align:center;
        margin-bottom:2em;
        max-width: 100%;

    }
`;

const AboutImg = styled.img`
    height: 400px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
    margin: 10px 10px;
    @media screen and (max-width: 780px) {
        margin-left: 0;
        width: (max-width<400) ?  100% : 80%;
    }
`;

const ContainerWhite = styled.div`
    background-color: #ab4;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
`;

const wrapContainer = styled.div`
    height:100vh;
    width: 100%;
    display : flex;
    align-items: center;
    flex-direction: column;
`;

const CarouselContainer = styled.div`
    position: relative;
   
    @media screen and (max-width: 780px) {
     

  };
`;

const carouselStyle = {
    interval: 500,
    cycle: false,
};

const DivForImage = styled.div`
    width :65%;
    height: 45%;
    
    align-items:center;
    justify-content: center;
    @media screen and (max-width: 780px) {
      width : 100% !important;
      height:10%;
      padding :5px 30px;

  };
    

`;

const ProductPic = styled.img`
    width:  35vw;
    height: 325px;
    -o-object-fit: contain;
    object-fit: contain;
    @media screen and (max-width: 780px) {
        width: 50vw;
        height: 225px;
        
    }

`;
const CursulitemContainer = styled.div`
    width : 100%;
    height: 50%;
    padding :0px;
    align-items:center;
    justify-content: center;
    @media screen and (max-width: 780px) {
      width : 100% !important;
      padding:0px 0px 0px 0px;
      
   

  };
    

`;

const Mycarousel = styled.div``;


const Containerone = styled.div`
    width: 100%;
    min-height: 350px;
    padding-left:30px;
   
    align-items:center;
    @media screen and (max-width: 544px) {
        width: 100% !important;
        padding-left:0px;
        @media screen and (max-width: 300px) {
            width: 80% !important;
            padding-left:6px;
        }

`;
const ContainerAll = styled.div`
    width: 50%;
    height: 50%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding : 10px 0px;
  
    @media screen and (max-width: 1020px) {
        width:80%;
        @media screen and (max-width: 800px) {
            width: 50%;
            @media screen and (max-width: 670px) {
                width: 60%;
                @media screen and (max-width: 580px) {
                    width: 75%;
                    @media screen and (max-width: 480px) {
                        width: 90%;
                    }
                }
            }
            
        }
        
    }

`;
const currentLanguage = i18next.languages;
console.log(currentLanguage);

export function MoreAboutSection(props) {

    const [t, i18n] = useTranslation();

    useEffect(() => {
        Aos.init({duration:600})
    },[]);
    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
      });
      const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight
    });
    useEffect(() => (window.onresize = updateSize), []);

    let settings = {
        dots: false,
        infinite:false,
        speed:400, 
        slidesToShow: (size.x > 800 ) ? 1: 1,
        slidesToScroll:1,
        cssEase: 'linear',
        

    }

    return <MoreAboutContainer>
        <SectionTitle name='MoreAboutSection' className={i18n.language}>{t("aboutUsSection.aboutUs")}</SectionTitle>
        <Marginer direction='vertical' margin='2em'/>
        <AboutContainer>
            <AboutText data-aos="fade-right" className={i18n.language}>
                {t('aboutUsSection.abouText')}
            </AboutText>
            <Marginer direction='horizontal' margin='4em'/>
            <AboutImg src = {AboutImgUrl} data-aos="fade-left"/>
        </AboutContainer>
        <ContainerAll className='container mt-5 carousel'>
            
        <Slider {...settings}>
   
     {  /* <Containerone>
        <DivForImage>
        <ReactPlayer width={(size.x > 800 ) ? "150%" : "100%"} url='https://www.youtube.com/watch?v=d66N8b7P8Go' />
        </DivForImage>
        </Containerone> */}
        <Containerone>
        <DivForImage>
        <ReactPlayer width={(size.x > 800 ) ? "150%" : "100%"} url='https://www.youtube.com/watch?v=Yah38_b8VGs' />
        </DivForImage>
        </Containerone>
     
        </Slider>
        </ContainerAll>
    </MoreAboutContainer>
}
/* <CarouselContainer>
            <Mycarousel className='mycarousel'  style={carouselStyle} >  
            <Carousel fade={true}> 
                   <Carousel.Item>  
                   
                    <DivForImage>
                    <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />
                    </DivForImage>
                   </Carousel.Item>

                   <Carousel.Item> 
                    <DivForImage>
                    <ReactPlayer url='https://www.youtube.com/watch?v=lVJLNsLNnWs' />
                    </DivForImage>
                   </Carousel.Item>
              
                </Carousel>  
                    
            </Mycarousel> 
            </CarouselContainer>              */