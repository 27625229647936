export const theme = {
    primary : "#FE5617",
    light : "#F4F1F4",
    dark : "rgb(1,9,35)",
    lightest : "#fcecdd",
    redPink : "#E4013F",
    greenHerb: "#76B82A",
    greenDark: "#3c693e",
    graySub : "#A9A9A9",
    Bch : "#EFF0F2",
    OrangeZoo : "#F0830B"

}