import React from 'react'  

import Carousel from 'react-bootstrap/Carousel' 
import styled from 'styled-components';

import { TopSection } from '../container/homepage/topSection';
import { ProductCarousel } from './product';
import "../css/carousel.scss"; 
import {useTranslation} from 'react-i18next';


const CarouselContainer = styled.div`
    position: relative;
`;


const Mycarousel = styled.div`
`;

const carouselStyle = {
    interval: 3000,
    cycle: true,
};



export function BootstrapCarousel (props) { 
    const [t, i18n] = useTranslation();

    return ( 
        <CarouselContainer> 
            <Mycarousel className='mycarousel'  style={carouselStyle} >  
            
                <Carousel fade={true}> 
                    
                    <Carousel.Item style={{'height':"100vh"} }>  
                        <TopSection/>
                    </Carousel.Item > 

                    <Carousel.Item style={{'height':"100vh", alignItems:'center'}}>  
                        <ProductCarousel name = {t("products.Clinofine.name")} subtitle = {t("products.Clinofine.subtitle")} link = '/agriculture/clinofine'/>
                    </Carousel.Item>

                    <Carousel.Item style={{'height':"100vh"}}>  
                        <ProductCarousel name = {t("products.Clinomine.name")} subtitle = {t("products.Clinomine.subtitle")} link = '/agriculture/clinomine'/>
                    </Carousel.Item>  

                 </Carousel>  
            </Mycarousel>  
        </CarouselContainer>  


            );
}
