import React from 'react';
import { SectionTitle } from '../../components/sectionTitle';
import styled from 'styled-components';
import {Marginer} from '../../components/marginer'
import ImageSlider from '../../components/Slider'
import {Element} from 'react-scroll'
import OneSlide from '../../components/oneSlide';
import {useTranslation} from 'react-i18next';
import '../../css/divider.scss'
const ContainerTop = styled(Element)`
`;
const ContainerAll = styled.div`
    width: 100%;
    min-height: 400px;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    padding : 10px 100px;
    @media screen and (max-width: 780px) {
        padding : 10px 0px;
    }
`;

const Title = styled(SectionTitle)`
    width: 100%;
    align-items: center;
    padding : 10px 0;
    text-align: center;
`;


export function ProductSection(props) {
    const [t, i18n] = useTranslation();
    return (

        <ContainerTop>
            <Title className = {i18n.language} name='prodcutsSection'>
                {t('productSection.ourCategories')}
            </Title>
        <ContainerAll>
        <Marginer direction='vertical' margin='2em'/>
            <OneSlide/>
        </ContainerAll>
        </ContainerTop>

    )
}