import React from 'react';

import styled from 'styled-components';
import {theme} from '../../theme'
import {Marginer} from '../marginer'
import BackgroundImg from "../../assets/pictures/soil_plant.png" 
import {useTranslation} from 'react-i18next';
import { FiChevronRight } from 'react-icons/fi';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory } from "react-router-dom";


const BackgroundFilter = styled.div`
    background-color : rgb(1,9,35);
    width: 100%;
    height:100vh;
    align-items: center;
    flex : 1;
    justify-content : 'center';
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 780px) {
        flex-direction: row;
    }
`;

const Title = styled.h1`
    font-size : 80px;
    font-weight:500;
    font-height:1.4;
    color: #fff;
    text-align: -webkit-auto;

    @media screen and (max-width: 780px) {
        text-align: center;
        @media screen and (max-width: 380px) {
            font-size : 60px;
        }
    }

`;

const Subtitle = styled.h1`
    font-size : 27px;
    font-weight:500;
    font-height:1.4;
    color: #fff;
    text-align: -webkit-auto;

    @media screen and (max-width: 780px) {
        text-align: center;
    }

`;

const ProductPic = styled.img`
    width: 25em;
    max-width : 40%;

`;

const TextContainer = styled.div`
    display: flex;
    flex:1;
    flex-direction : column;
    max-width : 40%;

    @media screen and (max-width: 780px) {
        max-width : 85%;
        margin-bottom : 2em;
        align-items:center;
    }
`;

const ImageContainer = styled.div`
    align-items: center;
    flex : 1;
    justify-content : 'center';
    display: flex;

`;

const InnerRow = styled.div`
    flex-direction: row;
    flex : 1;
    display: flex;
    max-width : 60%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    margin : 0px 150px;
    left:50%;
    transfrom: translateX(-50%);

    @media screen and (max-width: 780px) {
        flex-direction: column;
        flex-wrap: wrap-reverse;
        justify-content: center;
        margin : 0;
        max-width : 100%;
        align-items: center;
        left:0%;
        transfrom: translateX(0%);
    }
`;

const ContainerButton = styled.div`
    display:flex;
    flex-direction: row;
    transition: all 200ms ease-in-out;
    align-items:center;
    &:hover{
        .arrow{
            color:${theme.primary};   
        }
        h1{
            color:${theme.primary};   
        }

    }
`;


const TextButton = styled.h1`
    font-size : 18px;
    font-weight:400;
    font-height:1.4;
    color: #fff;
    text-align: -webkit-auto;
    cursor : pointer;
    transition: all 200ms ease-in-out;
    @media screen and (max-width: 780px) {
        text-align: center;
    }
`;

const Icon = styled(FiChevronRight)`
    color: #fff;
    cursor:pointer;

`;

const IconAr = styled(FiChevronLeft)`
    color: #fff;
    cursor:pointer;

`;

export function ProductCarousel(props) {

    const [t, i18n] = useTranslation();
    const { name, subtitle, link } =  props;
    const history = useHistory();
    const navigateTo = () => history.push(link);

    return(
        <BackgroundFilter>

            <InnerRow>
                <TextContainer>
                    <Title className = {i18n.language}>{name}</Title>
                    <Marginer direction="vertical" margin="1em"/>
                    <Subtitle className = {i18n.language}>{subtitle}</Subtitle>
                    <Marginer direction="vertical" margin="1em"/>
                    <ContainerButton className = {i18n.language} onClick = {navigateTo}>
                        <TextButton className = {i18n.language}>{t("productSection.seeMore")}</TextButton>
                        <Marginer direction="horizontal" margin="5px"/>
                        {i18n.language == "fr" ? <Icon className = 'arrow'/> : <IconAr className = 'arrow'/>}
                    </ContainerButton>
                </TextContainer>
                <Marginer direction="horizontal" margin="2em"/>
                    <ProductPic src = {BackgroundImg}/>
            </InnerRow>
        </BackgroundFilter>
    )
}

/*

*/