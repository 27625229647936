import React, {useEffect, useState} from 'react'
import Slider from 'react-slick';
import ProductPlantImg from '../assets/img/plant2.jpg'
import ProductAnimalImg from '../assets/img/chickenTest.jpg'
import styled from 'styled-components';
import {Marginer} from './marginer';
import { useHistory } from "react-router-dom";
import {useTranslation} from 'react-i18next';
import ReactLoading from 'react-loading';
import Aos from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import { loadNamespaces } from 'i18next';
import { blue } from '@material-ui/core/colors';

const server="https://admin.adhprotecleans.com";
const ContainerAll = styled.div`
    width: 75%;
    min-height: 350px;
    display: flex;
    flex-direction: row;
    justify-content: 'center';
    item-align: center;
    
    padding : 10px 0;
    @media screen and (max-width: 1060px) {
        flex-direction: column;
        width: 60% !important;
        @media screen and (max-width: 544px) {
            width: 70% !important;
            @media screen and (max-width: 300px) {
                width: 80% !important
            }
            
        }
        
    }

`;
const Containerone = styled.div`
    width: 50%;
    min-height: 350px;
    @media screen and (max-width: 544px) {
        width: 100% !important;
        @media screen and (max-width: 300px) {
            width: 80% !important
        }
        
    }
    
}
`;

const MarginCustom = styled(Marginer)`
    direction:vertical;
    margin:1em;
    @media screen and (max-width: 1060px) {
        &:not(:last-of-type) {
            margin: 2em;
        }
    }
    @media screen and (max-width: 480px) {
        width: 100%;
    }
`;
const Example = ({ spin }) => (
    <ReactLoading type={spin} color={blue} height={667} width={375} />
);
//? history.push({pathname:'/agriculture',state:{idscat: category.id}}):history.push({pathname:'/zootechnie',state:{idscat: category.id}})
function OneSlide() {
    const history = useHistory();
    const [t, i18n] = useTranslation();
    const [categories,getCategories]=useState();
    const [id,setid]=useState("");
    const navigateToAgr = (v) =>  history.push({pathname:'/agriculture'}); //eg.history.push('/login');
    const navigateToAvc = (v) =>  history.push({pathname:'/zootechnie'}); //eg.history.push('/login');


         
    useEffect(() => {
        Aos.init({duration:6000})
    },[]);  
   


    const mygetcategories = async () => {
        console.log("1a1a")
        const cats = await axios.get(server+'/api/v1/category',{
            headers: {
              "Access-Control-Allow-Origin": "*"
            }
          })
        console.log("aaa")
        getCategories(cats.data.category);
    
       };
       
       useEffect(() => {
        mygetcategories();
       }, []);
 



    let settings = {
        infinite:true,
        speed:500, 
        dots: false,
        prevArrow: false,
        slidesToShow:2,
        slidesToScroll:1,
        cssEase: 'linear'
    } 
    
   

    if(categories==null) return (
        <ContainerAll>
  
    {/*        <div className='card-wrapper-one' data-aos="zoom-in-up"  onClick= {navigateToAvc}>
                <div className='card-one'>
                    <div className='card-image-one'>
                        <div className='someParent'>
                            <img src={ProductAnimalImg}/>
                        </div>
                    </div>
                    <div className='details-one'>
                    <h2 className = {i18n.language} >{t("productSection.aviculture")}</h2>
                    </div>
                </div>
            </div>
            <div className='card-wrapper-one'  onClick= {navigateToAgr} data-aos="zoom-in-up">
                <div className='card-one' align-items= 'flex-end' display= 'flex'>
                    <div className='card-image-one'>
                        <div className='someParent'>
                            <img src={ProductPlantImg}/>
                        </div>
                    </div>
                    <div className='details-one'>
                        <h2 className = {i18n.language} >{t("productSection.agriculture")}</h2>
                    </div>
                </div>
    </div>*/}
            <MarginCustom/>
        </ContainerAll>);

   const Categorysection=categories.map((category)=>{
        return (
        <Containerone>
                <div className='card-wrapper-one'  onClick={(v)=>{
                    console.log("idiaaaa"+category.id);
                    category.name =="Agriculture"? history.push({pathname:'/agriculture',state:{idscat: category.id}}):history.push({pathname:'/zootechnie',state:{idscat: category.id}})}}  data-aos="zoom-in-up">
                <div className='card-one' align-items= 'flex-end' display= 'flex'>
                    <div className='card-image-one'>
                        <div className='someParent'>
                        <img  src={category.path_org}/>
                        </div>
                    </div>
                    <div className='details-one'>
                        <h2 className = {i18n.language} >{category.name}</h2>
                    </div>
                </div>
                </div>
                <MarginCustom/>
               
               </Containerone>);
    })
    return (
            <ContainerAll>
            {Categorysection}
           </ContainerAll>

    )
}

export default OneSlide
/*        <ContainerAll>
            <div className='card-wrapper-one'  onClick= {navigateToAgr} data-aos="zoom-in-up">
                <div className='card-one'  align-items='flex-end' display='flex'>
                    <div className='card-image-one'>
                        <div className='someParent'>
                            <img src={ProductPlantImg}/>
                        </div>
                    </div>
                    <div className='details-one'>
                        <h2 className = {i18n.language} >{t("productSection.agriculture")}</h2>
                    </div>
                </div>
            </div>
            <MarginCustom/>
            <div className='card-wrapper-one' data-aos="zoom-in-up"  onClick= {navigateToAvc}>
                <div className='card-one'>
                    <div className='card-image-one'>
                        <div className='someParent'>
                            <img src={ProductAnimalImg}/>
                        </div>
                    </div>
                    <div className='details-one'>
                    <h2 className = {i18n.language} >{t("productSection.aviculture")}</h2>
                    </div>
                </div>
            </div>
        </ContainerAll> 
        
            useEffect(()=>{ 
        
            axios({
            method: 'get',
            url: 'http://websiteadmin.com/api/v1/category',
    
          }).then((response)=>{
          
            if(response["status"]==200){

             getCategories(response["data"]["category"]) }
    
          }).catch((err)=>{alert(err)});
          axiosRetry(axios, {
            retries: 3, // number of retries
            retryDelay: (retryCount) => {
              console.log(`retry attempt: ${retryCount}`);
              return retryCount * 2000; // time interval between retries
            },
            retryCondition: (error) => {
              // if retry condition is not specified, by default idempotent requests are retried
              return error.response.status === 503;
            },
            });
    },[categories])
        
        
    fetch('http://websiteadmin.com/api/v1/category')
    .then(response => response.json())
    .then(data=>{
        console.log("data+++++");
        getCategories(data.category);
     
    });*/