import React from 'react';
import styled , { css } from 'styled-components'

import LogoC from '../../assets/logo/logo.png'
import { theme } from '../../theme'
import { useTranslation } from 'react-i18next';
import { Marginer } from '../marginer';
import i18n from 'i18next';

const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`; 

const LogoImg = styled.img`
    width: 34px;
    height: 22px;
`;

const LogoText = styled.div`
    font-size:"15px" ;
    font-weight : 900;
    @media screen and (max-width: 380px) {
        font-size : 13px;
    }
`;


export function Logo(props) {
    const [t, i18n] = useTranslation();
    const { link, color } =  props;


    return <LogoContainer>
        <LogoImg src = {link} />
        <Marginer direction='horizontal' margin='1em'/>
        <LogoText className = {i18n.language+"logo"}>{t('navbar.ADH')}</LogoText>
    </LogoContainer>
}