import React from 'react';
import styled from "styled-components";
import {Marginer} from '../../components/marginer';
import {Button} from '../../components/button';
import { DownArrow } from '../../components/downArrow';
import {Element, scroller} from 'react-scroll';
import LogoC from '../../assets/logo/logo.png';
import {theme} from '../../theme.jsx';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import '../../css/divider.scss'



const TopContainer = styled.div`
    width : 100%;
    height : 100vh;
    padding : 0;
    background-color : ${theme.light};
    position: relative;
    background-position: center;
    background-size: cover;
    transform : [ { scaleX : 2 } ];
    borderBottomStartRadius : 200;
    borderBottomEndRadius : 200;
    overflow : 'hidden',
    shadow-offset:{  width: 10,  height: 10,  },
    shadow-color: 'black',
    shadow-opacity: 1.0,
    margin:10px 10px;
`;

const BackgroundFilter = styled.div`
    position: 'absolute';
    width: 100%;
    height:100%;
    display : flex;
    flex-direction: column;
    align-items: center;
    flex : 1;
    justify-content : center;
`;

const MotivationalText = styled.h1`
    margin : 0;
    font-size : 33px;
    font-weight:500;
    font-height:1.4;
    color: #011689;
    text-align: center;

`;

const LogoImg = styled.img`
    width: 10em;
    height: 6.5em;
`;

const LogoText = styled.div`
    font-size: 20px;
    color: ${theme.primary};
    font-weight : 900;
`;


export function TopSection (props) {

    const [t, i18n] = useTranslation();
 
    const scrollToNextSection = () => {
        scroller.scrollTo("servicesSection",{smooth : true, duration:1500})
    }
    return  <Element name = "topSection">
                <div className='divider'>
        <TopContainer>
            <BackgroundFilter className = {i18n.language}>
                <LogoImg src = {LogoC}/>
                <Marginer direction = 'vertical' margin='0.5em'/>
                <LogoText className = {i18n.language}>{t('navbar.ADH')}</LogoText>                
                <Marginer direction = 'vertical' margin='3em'/>
                <MotivationalText className = {i18n.language}>{t('topSection.subOne')}</MotivationalText>
                <MotivationalText className = {i18n.language}>{t('topSection.subTwo')}</MotivationalText>
            </BackgroundFilter>
        </TopContainer>
        </div>
    </Element>

}