import React from 'react';
import Slider from '../components/Slider';
import styled from 'styled-components';
import { SectionTitle } from '../components/sectionTitle';
import {Marginer} from '../components/marginer'
import '../css/App.scss';
import {useTranslation} from 'react-i18next';
import {theme} from '../theme';
import {TopSection} from './topSectionZoo'
import { useLocation } from "react-router-dom";
const Container = styled.div`
    width:100%;
    overflow-x:hidden;
    height:100%;
    display: flex;
    flex-direction: column;
    background-color: ${theme.light};
    align-items:center; 
`;

const ContainerTop = styled.div`
    display : flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    justify-content: flex-start;
    max-height: 100vh;

    width: 80%;
    padding: 4em 0em;
    @media screen and (max-width: 780px) {
        width: 100%;
        max-height: 100%;
        padding: 1em 2em;

    }
`;


const ContainerInner = styled.div`
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display : flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    padding : 0em 2em;
    padding-bottom:2em;
    justify-content: center;
    flex-shrink : 1;
    @media screen and (max-width: 780px) {
        width: 100%;
        padding : 0;
        overflow: auto,
    }
`;

const ContainerUl = styled.div`
    padding:0px 2em;
    align-items: flex-start;
    text-align: -webkit-auto;
`;

const SectionTitleCus = styled(SectionTitle)`
    text-align : center;
    color:#fff;
    background-color: ${theme.dark};
    width : calc(100% + 64px) !important;
    padding: 10px 10px;
    left:0;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
    @media screen and (max-width: 780px) {
        width : 100% !important;

    };
`;

export function ProductAvc(props) {
    const [t, i18n] = useTranslation();
    const location = useLocation();
        return <Container> 
                <TopSection/>
                <Marginer direction='vertical' margin='5em'/>
                <SectionTitle className = {i18n.language}>{t("productSection.ourProducts")}</SectionTitle>
                <Slider idcategory={props.location.state.idscat}/>
                <Marginer direction="vertical" margin="4em"/>
            </Container>
}