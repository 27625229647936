import './css/App.scss';
import 'bootstrap/dist/css/bootstrap.css';
import { Homepage } from './container/homepage';
import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import { useTranslation } from 'react-i18next';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import { ProductAgr } from './Agriculture';
import { ProductAvc } from './zootechnie';
import { ProductPresClino } from './Agriculture/clinofine';
import { ProductPresClinoM } from './Agriculture/clinomine';
import { ProductPresPara } from './zootechnie/paratox';
import ButtonAppBar from './components/navbar';

function App (){
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  document.oncontextmenu = new Function("return false;");

  
  return (
    <Router>
      <div> 
      <CssBaseline/>
      {/*<Switch>
        <Route path="/" component={ButtonAppBar}/>
      </Switch>*/}
        <Switch>
        <Route path='/' exact component={Homepage}/>
        <Route path='/agriculture' exact component={ProductAgr}/>
        <Route path='/zootechnie' exact component={ProductAvc}/>        
        <Route path='/agriculture/clinofine' exact component={ProductPresClino}/>
        <Route path='/agriculture/clinomine' exact component={ProductPresClinoM}/>
        <Route path='/zootechnie/paratox' exact component={ProductPresPara}/>
        </Switch>
      </div>
    </Router>
    
  );
}


export default App;
