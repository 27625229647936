import React , { Fragment, useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from "@material-ui/icons/Menu";
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LogoC from '../../assets/logo/logo.png'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {scroller} from 'react-scroll'
import 'react-dropdown/style.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import {Logo} from '../../components/logo'
import {themeC} from '../../theme'
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import styled from 'styled-components';
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import Paper from '@material-ui/core/Paper';
import { useHistory } from "react-router-dom";
import './navGnrl.scss';
import frIco from '../../assets/icon/FR.png'
import DzIco from '../../assets/icon/DZ.png'
import axios from 'axios';
const server="https://admin.adhprotecleans.com";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflowX: 'hidden !important',
        width: '100vw'
    },
    titleTransparent: {
        flexGrow:1,
        color: '#F4F1F4',
    },
    titleSolid: {
        flexGrow:1,
        color: 'black',
    },
    appBarTransparent: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        maxWidth: '100vw',
        overflowX:'clip',
    },
    appBarSolid: {
        backgroundColor: '#F4F1F4',
        maxWidth: '100vw',
        overflowX:'clip',

    },
    logo: {
        maxWidth: 30,
        marginRight: theme.spacing(1),
    },
    ButtonTransparent: {
        color: '#000',
        [theme.breakpoints.down('sm')]: { display: 'none', },

    },
    ButtonSolid: {
        color: 'black',
        [theme.breakpoints.down('sm')]: { display: 'none', }

    },

    LngTransparent: {
        color: '#F4F1F4',
        fontSize: '25px'
    },
    LngSolid: {
        color: 'black',
    },

    MenuTransparent: {
      color: '#000',
      [theme.breakpoints.up('md')]: { display: 'none', },

    },

    MenuSolid: {
        color: 'black',
        [theme.breakpoints.up('md')]: { display: 'none', },

    },

}));

const theme = createMuiTheme({
    
    overrides: {
        MuiButton: {
          label: {
            fontFamily: [
                'Cairo',
                'sans-serif',
            ].join(',')
          },
        },
      },
      MuiTab: {
        selected: {
          color: '#ff6701',
          backgroundColor : '#ff6701',
        }
      },
    direction: i18n.dir(),
});

const CustomButton = styled(Button)`
    font-family: 'Cairo', sans-serif;
`;

const ContainerRow = styled.div`
    display : flex;
    flex-direction : row;
`;

    
export default function ButtonAppBar() {
    const [categories,getCategories]=useState();
    const [idarg,setagr]=useState();
    const [idavg,setavr]=useState();
    const mygetcategories = async () => {
        console.log("nav bar")
        const cats = await axios.get(server+'/api/v1/category',{
            headers: {
              "Access-Control-Allow-Origin": "*"
            }
          })
        console.log(cats.data.category[1]);
        setagr(cats.data.category[1].id);
        setavr(cats.data.category[0].id);
        getCategories(cats.data.category);
    
       };
       
       useEffect(() => {
        mygetcategories();
       }, []);

    const trigger = useScrollTrigger();

    const [t, i18n] = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [isAr, setIsAr] = useState(i18n.language == 'fr' ? false : true);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };  

    const scrollToNextSection = () => {
        scroller.scrollTo("prodcutsSection",{smooth : true, duration:1500})
    }

    const scrollToNextSectionAbout = () => {
        scroller.scrollTo("MoreAboutSection",{smooth : true, duration:1500})
    }

    const scrollToNextSectionObligations = () => {
        scroller.scrollTo("Obligations",{smooth : true, duration:1500})
    }

    const classes = useStyles();
    const [navBackground, setNavBackground] = useState('appBarTransparent');
    const [titleColor, setTitleColor] = useState('titleTransparent');
    const [buttonColor, setButtonColor] = useState('buttonTransparent');
    const [MenuColor, setMenuColor] = useState('MenuTransparent');
    const [lngColor, setLngColor] = useState('buttonTransparent');


    const navRef = React.useRef();
    navRef.current = navBackground;

    const TitleRef = React.useRef();
    TitleRef.current = titleColor;

    const ButtonRef = React.useRef();
    ButtonRef.current = buttonColor;

    const LngRef = React.useRef();
    LngRef.current = lngColor;

    const MenuRef = React.useRef();
    MenuRef.current = MenuColor;

    useEffect(() => {
        const show = window.scrollY > window.innerHeight

        if (show) {
            setButtonColor('ButtonSolid');
            setLngColor('LngSolid');
            setMenuColor('MenuSolid');

        } else {
            setButtonColor('ButtonTransparent');
            setLngColor('LngTransparent');
            setMenuColor('MenuTransparent');
        }

        const handleScroll = () => {
            const show = window.scrollY > window.innerHeight;
            handleClose();
            if (show) {
                setNavBackground('appBarSolid');
                setTitleColor('titleSolid');
                setButtonColor('ButtonSolid');
                setLngColor('LngSolid');
                setMenuColor('MenuSolid');

            } else {
                setNavBackground('appBarTransparent')
                setTitleColor('titleTransparent');
                setButtonColor('ButtonTransparent');
                setLngColor('LngTransparent');
                setMenuColor('MenuTransparent');
            }
        }
        document.addEventListener('scroll',handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    },[])


    const languages = [ "Français", "العربية"];

    function changeLangaugeClick(lang) {
        i18n.changeLanguage(lang);
        setIsAr(!isAr);
    }

    const history = useHistory();
    const navigateToHome = () => history.push('#'); //eg.history.push('/login');
    const navigateToAgr = () => history.push({pathname:'/agriculture', state:{idscat: idarg}}); //eg.history.push('/login');
    const navigateToAvc = () => history.push({pathname:'/zootechnie', state:{idscat: idavg}});

    return ( 
        <ThemeProvider theme={theme}>
            <div className="gnrl">
            <Slide appear={false} direction="down" in={!trigger} >
                <AppBar position="fixed" className = {classes[navRef.current]}>
                    <Toolbar>
                        <Logo link={LogoC} color="#011689" alt="logo" className={classes.logo} />
                        <Typography variant = 'h6'  className = {classes[TitleRef.current]}>
                        </Typography>
                            <Tabs
                                value={value}
                                indicatorColor='primary'
                                textColor="primary"
                                onChange={handleChange}
                                aria-label="disabled tabs example"
                                className={i18n.language}
                                TabIndicatorProps={{
                                    style: { background: "#011689" }
                                  }}
                            >
                                <Tab label = {t('navbar.home')} color="inherit" className={classes[ButtonRef.current]} onClick={navigateToHome}/>
                                <Tab label = {t("productSection.agriculture")} color="inherit" className={classes[ButtonRef.current]} onClick={navigateToAgr}/>
                                <Tab style={{paddingLeft: 0, paddingRight: 0}} label = {t("productSection.aviculture")} color="inherit" className={classes[ButtonRef.current]} onClick={navigateToAvc}/>
                               {/* <Tab label = {t('obligationSection.obligations')}  color="inherit" className={classes[ButtonRef.current]} onClick={scrollToNextSectionObligations}/>
                                <Tab label = {t('aboutUsSection.aboutUs')} color="inherit" className={classes[ButtonRef.current]} onClick={scrollToNextSectionAbout}/>
                                */}
                            </Tabs>
                            {/*<Button  color="inherit" className={classes[LngRef.current]}>{isAr ? getUnicodeFlagIcon('DZ') : getUnicodeFlagIcon('FR')}</Button>
                           <Button onClick = {() => isAr ? changeLangaugeClick('fr') : changeLangaugeClick('ar')}><img src={ isAr ? DzIco  : frIco} alt="language" width = "30px"/></Button>*/}

                            <IconButton className={classes[MenuRef.current]} style={{padding: 0}}  aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} color="inherit">
                            <MenuIcon />
                        </IconButton>
                            <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            >
                           {/* <MenuItem onClick={navigateToHome}className = {i18n.language} fontFamily="font-family: 'Cairo', sans-serif">{t('navbar.home')} </MenuItem>*/} 
                            <MenuItem onClick={navigateToAgr} className = {i18n.language}>{t("productSection.agriculture")}</MenuItem>
                            <MenuItem className = {i18n.language} onClick={navigateToAvc}>{t("productSection.aviculture")}</MenuItem>
                            </Menu>
                    </Toolbar>
                </AppBar>
            </Slide>
            
            </div>
        </ThemeProvider>

    )
}