import React from 'react'
import Slider from 'react-slick';
import ProductPlantImg from '../assets/ProductPhotography/Clinofine-1kg.jpg'
import ProductPlantImgSecond from '../assets/ProductPhotography/product-3.jpg'
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import Aos from 'aos';
import axios from 'axios';
import 'aos/dist/aos.css';
import {CardSlider} from '../components/card'
import { Marginer } from './marginer';

const server="https://admin.adhprotecleans.com";

const ContainerAll = styled.div`
    width: 60%;
    height: 80vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding : 10px 0;
    @media screen and (max-width: 1020px) {
        width: 90%;
        @media screen and (max-width: 800px) {
            width: 50%;
            @media screen and (max-width: 670px) {
                width: 60%;
                @media screen and (max-width: 580px) {
                    width: 75%;
                    @media screen and (max-width: 480px) {
                        width: 90%;
                    }
                }
            }
            
        }
        
    }

`;
const Containerone = styled.div`
    width: 50%;
    min-height: 350px;
    padding : 0px 0;
    @media screen and (max-width: 544px) {
        width: 100% !important;
        @media screen and (max-width: 300px) {
            width: 80% !important
        }

`;
function ImageSlider(props) {
    const[ProducutsAgri,setProductsAgri]=useState(null);
   

    useEffect(() => {
        Aos.init({duration:1000})
    },[]);

    const myagrproducts = async () => {
        console.log("vvvvv "+props.idcategory);
        const agrproducts = await axios.get('https://admin.adhprotecleans.com/api/v1/category/'+props.idcategory+'/product',
        {
            headers: {
              "Access-Control-Allow-Origin": "*"
            }
          });
        //setUsers(cats.data.results);
        setProductsAgri(agrproducts.data.products) ;
    
     
    
       };
       
       useEffect(() => {
        myagrproducts();
       }, []);

   /* useEffect(()=>{
        axios({
              method: 'get',
              url: 'http://websiteadmin.com/api/v1/category/2/product',
         
            }).then((response)=>{
              
              console.log(response["data"]);
              if(response["status"]==200)
              setProductsAgri(response["data"]["products"]) 
             
           
            }).catch((err)=>{console.log(err)});
      },[ProducutsAgri])*/

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
      });
      const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight
    });
    useEffect(() => (window.onresize = updateSize), []);
    
    const history = useHistory();
    const navigateToClino = () => history.push('/agriculture/clinofine');//eg.history.push('/login');
    const navigateToClinoM = () => history.push('/agriculture/clinomine');//eg.history.push('/login');


    let settings = {
        dots: true,
        infinite:false,
        speed:500, 
        slidesToShow:  (size.x > 800 ) ? 2: 1,
        slidesToScroll:1,
        cssEase: 'linear'
    }
    const [t, i18n] = useTranslation();

    if(ProducutsAgri==null)return (     
           <ContainerAll className='container mt-5 carousel'>
            <Slider {...settings}>
            </Slider>
            </ContainerAll>)

    const AgricultureProductSection=ProducutsAgri.map((ProductAgri)=>{
        return (
        <Containerone>
          <CardSlider id={ProductAgri.id}   name = {ProductAgri.name} linkImage = {ProductAgri.path_org} linkNavigate = '/agriculture/clinofine'/> 
        </Containerone>);
    })

    
    return ( 
        <ContainerAll className='container mt-5 carousel'>
        <Slider {...settings}>
           {AgricultureProductSection}
           

        </Slider>
        </ContainerAll>
    )
}

export default ImageSlider;


 /*<CardSlider name = {t("products.Clinofine.name")} linkImage = {ProductPlantImg} linkNavigate = '/agriculture/clinofine' />

    <CardSlider name = {t("products.Clinomine.name")} linkImage = {ProductPlantImgSecond} linkNavigate = '/agriculture/clinomine' />
    <CardSlider name = {t("products.Clinomine.name")} linkImage = {ProductPlantImgSecond} linkNavigate = '/agriculture/clinomine' />
    <CardSlider name = {t("products.Clinomine.name")} linkImage = {ProductPlantImgSecond} linkNavigate = '/agriculture/clinomine' />*/