import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';   
import 'bootstrap/dist/css/bootstrap-grid.css';   
import "slick-carousel/slick/slick.css";
import 'slick-carousel/slick/slick-theme.css'
import { BoxLoading } from 'react-loadingg';
import styled from 'styled-components'
import {theme} from './theme'
import {Marginer} from './components/marginer'

const Loader = styled.div`
  width: 100vw;
  height : 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${theme.dark}
`;

const Text = styled.h1`
    font-size : 18px;
    font-weight:400;
    font-height:1.4;
    color: ${theme.primary};
    text-align: -webkit-auto;
    cursor : pointer;
    transition: all 200ms ease-in-out;
    @media screen and (max-width: 780px) {
        text-align: center;
    }
`;

ReactDOM.render(
  <Suspense fallback= {(<Loader>
    <BoxLoading color={theme.primary} size='large'/>
    <Marginer direction= 'vertical' margin = '10em'/>
    <Text>Chargement ...</Text>
    </Loader>)}>  
    <App/>
  </Suspense>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
